const getConfig = () => {
  let environment = window.location.hostname.split(".")[2] ?? "localhost";

  switch (environment) {
    case "localhost":
      if (window.location.hostname.startsWith("ai.")) {
        return {
          SERVER_API_URL: "http://localhost:4000",
          // http://localhost:4000
          LIVEBOT_URL: "http://localhost:3001",
          PUBLIC_DOMAIN: "ai.localhost",
          PAGE_DOMAIN: "ai.localhost",
          PUBLIC_GOOGLE_APP_ID:
            "471909029023-13g22jeu5vbaosvrluu2nhe884aohf2b.apps.googleusercontent.com",
          PUBLIC_GOOGLE_CLIENT_ID:
            "533552806890-nqbc119bn07u8th8t0b9r9j96oj1igtb.apps.googleusercontent.com",
          GOOGLE_PICKER_DEVELOPER_KEY:
            "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
          AUTH_FACEBOOK_APP_ID: 956127348390513, //old cbb login app
          PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
          PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
          PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
          CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
          FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz", //local jp API key
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
          isAI: true,
          META_API_VERSION: "v19.0",
          META_CONFIGS: {
            login: "883317163601799",
            pages: "1528923587675165", // FB pages
            whatsapp: "2357118394498759", // WA business account
          },
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "development",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LMK5oFas3GhAJRiv9K19NjmkoXHIXgzGdTuK4XSkKRFxJrKklXiKJ65tg2s3MXIEBbPnjE3K131pNLXcj39pxLR00qA7RU3Zk",
          MONTHLYPLAN: "price_1PXd6cFas3GhAJRiDsUXqkT8",
          YEARLYPLAN: "price_1QHOicFas3GhAJRirCygOqgQ",
          MONTHLYPLAN_PROFESSIONAL: "price_1PXd7jFas3GhAJRiF111LQdI",
          YEARLYPLAN_PROFESSIONAL: "price_1QHOQdFas3GhAJRidTJP8vl2",
          TRIALPLAN: "price_1PXd5LFas3GhAJRi3AtOqvUS",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          SALESFORCE_CLIENT_ID:
            "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
          NOTION_CLIENT_ID: "128d872b-594c-80b5-94de-00374f66ae4c",
          CONFLUENCE_CLIENT_ID: "3UcrhP9S9V9hbPN6kd2EX9dND7twMlwN",
          INTERCOM_CLIENT_ID: "7d532053-5984-4ce8-bfb2-4efd884f75e5",
          ZENDESK_CLIENT_ID: "",
          ZENDESK_REDIRECT_URI: "",
        };
      } else {
        return {
          SERVER_API_URL: "http://localhost:4000",
          LIVEBOT_URL: "http://localhost:3001",
          PUBLIC_DOMAIN: "localhost",
          PAGE_DOMAIN: "localhost",
          PUBLIC_GOOGLE_APP_ID:
            "471909029023-13g22jeu5vbaosvrluu2nhe884aohf2b.apps.googleusercontent.com",
          PUBLIC_GOOGLE_CLIENT_ID:
            "533552806890-nqbc119bn07u8th8t0b9r9j96oj1igtb.apps.googleusercontent.com",
          AUTH_FACEBOOK_APP_ID: 1093472884694695, //old OMNI login app
          PUBLIC_FACEBOOK_APP_ID: 1093472884694695,
          PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
          PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
          CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
          FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
          isAI: false,
          META_API_VERSION: "v19.0",
          GOOGLE_PICKER_DEVELOPER_KEY:
            "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
          META_CONFIGS: {
            login: "883317163601799",
            pages: "1528923587675165", // FB pages
            whatsapp: "2357118394498759", // WA business account
          },
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "development",
          MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
          YEARLYPLAN: "price_1QHi66AMQsTGGiHosqx4P3fs",
          TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          SALESFORCE_CLIENT_ID:
            "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
          INTERCOM_CLIENT_ID: "7d532053-5984-4ce8-bfb2-4efd884f75e5",
          ZENDESK_CLIENT_ID: "",
          ZENDESK_REDIRECT_URI: "",
        };
      }
    case "ailocal":
      return {
        SERVER_API_URL: "http://localhost:4000",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        GOOGLE_PICKER_DEVELOPER_KEY: "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
        AUTH_FACEBOOK_APP_ID: 956127348390513, //old cbb login ap
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
        PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
        CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
        FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
        PUBLIC_DOMAIN: "app.chatbotbuilder.ailocal",
        PAGE_DOMAIN: "chatbotbuilder.ailocal",
        LIVEBOT_URL: "http://localhost:3001",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "83d6c352-cd2a-4c84-8efe-12667c2a4d29",
        isAI: true,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "development",
        SALESFORCE_CLIENT_ID:
          "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
        NOTION_CLIENT_ID: "128d872b-594c-80b5-94de-00374f66ae4c",
        CONFLUENCE_CLIENT_ID: "3UcrhP9S9V9hbPN6kd2EX9dND7twMlwN",
        INTERCOM_CLIENT_ID: "7d532053-5984-4ce8-bfb2-4efd884f75e5",
        ZENDESK_CLIENT_ID: "",
        ZENDESK_REDIRECT_URI: "",
      };
    case "local":
      return {
        SERVER_API_URL: "http://localhost:4000",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        AUTH_FACEBOOK_APP_ID: 1093472884694695, //old OMNI login ap
        PUBLIC_FACEBOOK_APP_ID: 1093472884694695,
        PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
        PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
        CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
        FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
        PUBLIC_DOMAIN: "app.engage.local",
        LIVEBOT_URL: "http://localhost:3001",
        PAGE_DOMAIN: "engage.local",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
        isAI: false,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "development",
        RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
        STRIPE_PUBLIC_KEY:
          "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
        GOOGLE_PICKER_DEVELOPER_KEY: "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
        MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
        YEARLYPLAN: "price_1QHi66AMQsTGGiHosqx4P3fs",
        TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
        MONTHLYBRANDING: "price_1PUNvvAMQsTGGiHojYj1anlG",
        YEARLYBRANDING: "price_1PUNutAMQsTGGiHoxYfwJxBY",
        SALESFORCE_CLIENT_ID:
          "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
        INTERCOM_CLIENT_ID: "7d532053-5984-4ce8-bfb2-4efd884f75e5",
        ZENDESK_CLIENT_ID: "",
        ZENDESK_REDIRECT_URI: "",
      };
    case "chat":
      if (window.location.hostname.split(".")[0] === "ai")
        return {
          SERVER_API_URL: "https://api.outgrow.chat",
          PUBLIC_GOOGLE_APP_ID:
            "825285028982-ejjvllvr0aid7dnit7ca9aib01b25slt.apps.googleusercontent.com",
          PUBLIC_GOOGLE_CLIENT_ID:
            "533552806890-nqbc119bn07u8th8t0b9r9j96oj1igtb.apps.googleusercontent.com",
          GOOGLE_PICKER_DEVELOPER_KEY:
            "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
          AUTH_FACEBOOK_APP_ID: 956127348390513, //old cbb login app
          PUBLIC_FACEBOOK_APP_ID: 1460724304589936, //cbb integration app
          PUBLIC_INSTAGRAM_APP_ID: 1069239587937653, //cbb integration app insta
          PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
          CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
          PUBLIC_DOMAIN: "ai.outgrow.chat",
          PAGE_DOMAIN: "ai.outgrow.chat",
          LIVEBOT_URL: "https://liveai.outgrow.chat",
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "83d6c352-cd2a-4c84-8efe-12667c2a4d29",
          isAI: true,
          META_API_VERSION: "v19.0",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "staging",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LMK5oFas3GhAJRiv9K19NjmkoXHIXgzGdTuK4XSkKRFxJrKklXiKJ65tg2s3MXIEBbPnjE3K131pNLXcj39pxLR00qA7RU3Zk",
          MONTHLYPLAN: "price_1PXd6cFas3GhAJRiDsUXqkT8",
          YEARLYPLAN: "price_1QHOicFas3GhAJRirCygOqgQ",
          MONTHLYPLAN_PROFESSIONAL: "price_1PXd7jFas3GhAJRiF111LQdI",
          YEARLYPLAN_PROFESSIONAL: "price_1QHOQdFas3GhAJRidTJP8vl2",
          TRIALPLAN: "price_1PXd5LFas3GhAJRi3AtOqvUS",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          SALESFORCE_CLIENT_ID:
            "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
          NOTION_CLIENT_ID: "128d872b-594c-80b5-94de-00374f66ae4c",
          CONFLUENCE_CLIENT_ID: "3UcrhP9S9V9hbPN6kd2EX9dND7twMlwN",
          INTERCOM_CLIENT_ID: "7d532053-5984-4ce8-bfb2-4efd884f75e5",
          ZENDESK_CLIENT_ID: "",
          ZENDESK_REDIRECT_URI: "",
        };
      else
        return {
          SERVER_API_URL: "https://api.outgrow.chat",
          PUBLIC_GOOGLE_APP_ID:
            "825285028982-ejjvllvr0aid7dnit7ca9aib01b25slt.apps.googleusercontent.com",
          GOOGLE_PICKER_DEVELOPER_KEY:
            "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
          AUTH_FACEBOOK_APP_ID: 1093472884694695, //old OMNI login ap
          PUBLIC_FACEBOOK_APP_ID: 1093472884694695,
          PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
          PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
          CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
          FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
          PUBLIC_DOMAIN: "app.outgrow.chat",
          PAGE_DOMAIN: "omniengage.co",
          LIVEBOT_URL: "https://live.outgrow.chat",
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
          isAI: false,
          META_API_VERSION: "v19.0",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "staging",
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
          MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
          YEARLYPLAN: "price_1QHi66AMQsTGGiHosqx4P3fs",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
          SALESFORCE_CLIENT_ID:
            "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
          INTERCOM_CLIENT_ID: "7d532053-5984-4ce8-bfb2-4efd884f75e5",
          ZENDESK_CLIENT_ID: "",
          ZENDESK_REDIRECT_URI: "",
        };
    case "co":
      return {
        SERVER_API_URL: "https://api.omniengage.co",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        GOOGLE_PICKER_DEVELOPER_KEY: "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
        AUTH_FACEBOOK_APP_ID: 1093472884694695, //old OMNI login ap
        PUBLIC_FACEBOOK_APP_ID: 1093472884694695,
        PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
        PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
        CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
        FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
        RECAPTCHAKEY: "6Lf4NDgqAAAAALbxk3ZhEjoYkNvEswfuIQaq4eX1",
        PUBLIC_DOMAIN: "app.omniengage.co",
        PAGE_DOMAIN: "omniengage.co",
        LIVEBOT_URL: "https://<subDomain>.omniengage.co",
        TIMEKIT_API_KEY: "live_widget_key_XLsFIuOL345Gwtef0OQRcMYeNfjqAsYe", // Outgrow
        TIMEKIT_PROJECT_ID: "a0aa96e2-d35c-476b-95ed-790c648e8453", // omni
        isAI: false,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "production",
        LIFETIMEPLAN1: "price_1PuYvoAMQsTGGiHopqQP8rbA",
        LIFETIMEPLAN2: "price_1PuYvrAMQsTGGiHoqHEC62Rf",
        MONTHLYPLAN: "price_1PUO77AMQsTGGiHo9aJ4kKk9",
        YEARLYPLAN: "price_1QHjZZAMQsTGGiHo3OKnQ9if",
        TRIALPLAN: "price_1PUO9WAMQsTGGiHoHFwmjojN",
        STRIPE_PUBLIC_KEY:
          "pk_live_51LDGaqAMQsTGGiHoxsGviZI53uKvzgMa6cinvyozi9JSVzVqLIuWpo5RqlSIzukKjUTRwJP5LOnGBwjenK7IGRxT00L6zOJlHO",
        SALESFORCE_CLIENT_ID:
          "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
        INTERCOM_CLIENT_ID: "",
        ZENDESK_CLIENT_ID: "",
        ZENDESK_REDIRECT_URI: "",
      };
    case "net":
      return {
        SERVER_API_URL: "https://api.chatbotbuilder.net",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        PUBLIC_GOOGLE_CLIENT_ID:
          "533552806890-nqbc119bn07u8th8t0b9r9j96oj1igtb.apps.googleusercontent.com",
        GOOGLE_PICKER_DEVELOPER_KEY: "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
        AUTH_FACEBOOK_APP_ID: 956127348390513, //old cbb login ap
        PUBLIC_FACEBOOK_APP_ID: 1460724304589936, //cbb integration app
        PUBLIC_INSTAGRAM_APP_ID: 2106755656362298, //cbb integration app insta
        PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
        CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
        FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
        PUBLIC_DOMAIN: "app.chatbotbuilder.net",
        RECAPTCHAKEY: "6LdhMVMqAAAAAApDW0lI1XlFGVPd-6J8qxtzAya9",
        PAGE_DOMAIN: "chatbotbuilder.net",
        LIVEBOT_URL: "https://<subDomain>.chatbotbuilder.net",
        TIMEKIT_API_KEY: "live_widget_key_XLsFIuOL345Gwtef0OQRcMYeNfjqAsYe", // Outgrow
        TIMEKIT_PROJECT_ID: "c7fa7704-7a34-4bc6-824f-9b2a2af62630", // chatBot
        isAI: true,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "production",
        STRIPE_PUBLIC_KEY:
          "pk_live_51LMK5oFas3GhAJRiwyHa2yRixlsElEgVtTdzEHJewj5IsXqzXs9H2G4XFVlgOVCHUcPqlKupy9xKhVVYdALXP0dW001w1n7F8a",
        MONTHLYPLAN: "price_1PTi7HFas3GhAJRiE6wlhc1L",
        YEARLYPLAN: "price_1QHjbrFas3GhAJRiUJBVs3cj",
        MONTHLYPLAN_PROFESSIONAL: "price_1PaxxUFas3GhAJRiSfZNXsjW",
        YEARLYPLAN_PROFESSIONAL: "price_1QHjbwFas3GhAJRiKcATfgcI",
        TRIALPLAN: "price_1PaxwjFas3GhAJRiocA8j7Yj",
        SALESFORCE_CLIENT_ID:
          "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
        NOTION_CLIENT_ID: "128d872b-594c-80b5-94de-00374f66ae4c",
        CONFLUENCE_CLIENT_ID: "3UcrhP9S9V9hbPN6kd2EX9dND7twMlwN",
        INTERCOM_CLIENT_ID: "",
        ZENDESK_CLIENT_ID: "",
        ZENDESK_REDIRECT_URI: "",
      };
    case "app":
      return {
        SERVER_API_URL: "http://localhost:4000",
        LIVEBOT_URL: "http://localhost:3001",
        PUBLIC_DOMAIN: "localhost",
        PAGE_DOMAIN: "localhost",
        PUBLIC_GOOGLE_APP_ID:
          "471909029023-13g22jeu5vbaosvrluu2nhe884aohf2b.apps.googleusercontent.com",
        GOOGLE_PICKER_DEVELOPER_KEY: "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
        PUBLIC_GOOGLE_CLIENT_ID:
          "533552806890-nqbc119bn07u8th8t0b9r9j96oj1igtb.apps.googleusercontent.com",
        AUTH_FACEBOOK_APP_ID: 956127348390513, //old cbb login ap
        PUBLIC_FACEBOOK_APP_ID: 1093472884694695,
        PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
        PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
        CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
        FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
        isAI: true,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "production",
        MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
        YEARLYPLAN: "price_1QHi66AMQsTGGiHosqx4P3fs",
        TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
        MONTHLYBRANDING: "price_1PUNvvAMQsTGGiHojYj1anlG",
        YEARLYBRANDING: "price_1PUNutAMQsTGGiHoxYfwJxBY",
        SALESFORCE_CLIENT_ID:
          "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
        INTERCOM_CLIENT_ID: "",
        ZENDESK_CLIENT_ID: "",
        ZENDESK_REDIRECT_URI: "",
      };
    default:
      return {
        SERVER_API_URL: "http://localhost:4000",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com", //prod
        AUTH_FACEBOOK_APP_ID: 956127348390513, //old cbb login ap
        PUBLIC_FACEBOOK_APP_ID: 1093472884694695,
        PUBLIC_INSTAGRAM_APP_ID: 452688490508976,
        PUBLIC_WHATSAPP_APP_ID: 2106755656362298,
        CALENDLY_CLIENT_ID: "pIz1FgXp1RzMbgQtImmL8RtRilkNIrTT2wpWd098oXI",
        FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
        PUBLIC_DOMAIN: "app.engage.local",
        LIVEBOT_URL: "http://localhost:3001",
        GOOGLE_PICKER_DEVELOPER_KEY: "AIzaSyBJI8JIHFNsURPz2-g3SE_CzZqw0CmZNR0",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "83d6c352-cd2a-4c84-8efe-12667c2a4d29",
        isAI: true,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "development",
        STRIPE_PUBLIC_KEY:
          "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
        MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
        YEARLYPLAN: "price_1QHi66AMQsTGGiHosqx4P3fs",
        TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
        MONTHLYBRANDING: "price_1PUNvvAMQsTGGiHojYj1anlG",
        YEARLYBRANDING: "price_1PUNutAMQsTGGiHoxYfwJxBY",
        SALESFORCE_CLIENT_ID:
          "3MVG9YDQS5WtC11rKOoecjsTdHqB.HVHb53M2jFbHbeXZ7xcfZXd7KqR.CQ0d3aKDuJel1YkR8O0h743N5JA2",
        INTERCOM_CLIENT_ID: "",
        ZENDESK_CLIENT_ID: "",
        ZENDESK_REDIRECT_URI: "",
      };
  }
};
//1460724304589936 cbb new app
export default getConfig;
